export enum AnalyticsActionTypes {
  SET_IS_QUOTE_REQUESTED_EVENT_SENT = 'analytics/SET_IS_QUOTE_REQUESTED_EVENT_SENT',
}

export interface AnalyticsState {
  isQuoteRequestedEventSent: Record<InsuranceType, boolean>,
}

export interface AnalyticsAction {
  type: AnalyticsActionTypes,
  payload: {
    insuranceType: InsuranceType,
    isQuoteRequestedEventSent: boolean,
  },
}

export type InsuranceType = 'health' | 'life' | 'medicare'
