import type {
  AnalyticsAction,
  AnalyticsState,
} from './types'
import { AnalyticsActionTypes } from './types'

const { SET_IS_QUOTE_REQUESTED_EVENT_SENT } = AnalyticsActionTypes

export const initialState: AnalyticsState = {
  isQuoteRequestedEventSent: {
    health: false,
    life: false,
    medicare: false,
  },
}

export default (state: AnalyticsState = initialState, action: AnalyticsAction): AnalyticsState => {
  switch (action.type) {
    case SET_IS_QUOTE_REQUESTED_EVENT_SENT:
      return {
        ...state,
        isQuoteRequestedEventSent: {
          ...state.isQuoteRequestedEventSent,
          [action.payload.insuranceType]: action.payload.isQuoteRequestedEventSent,
        },
      }

    default:
      return state
  }
}
