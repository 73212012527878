import type { AnalyticsState } from './analytics/types'
import type { AppState } from './app/types'
import type { AttributesState } from './attributes/types'
import type { CoverageLevelsState } from './coverageLevels/types'
import type { InsuranceTypesState } from './insuranceTypes/types'
import type { QuotesState } from './quotes/types'
import type { VehicleAttributesState } from './vehicleAttributes/types'

export interface GenericAction {
  type: string,
  payload?: any, // eslint-disable-line @typescript-eslint/no-explicit-any
}

export interface GenericState {
  data?: any, // eslint-disable-line @typescript-eslint/no-explicit-any
  cached: boolean,
  loading: boolean,
  error?: string | object,
}

export const genericInitialState = {
  data: undefined,
  cached: false,
  loading: false,
  error: undefined,
}

export interface RootState {
  analytics: AnalyticsState,
  app: AppState,
  attributes: AttributesState,
  coverageLevels: CoverageLevelsState,
  insuranceTypes: InsuranceTypesState,
  quotes: QuotesState,
  vehicleAttributes: VehicleAttributesState,
}

export type CacheResponse = Promise<boolean | void>
