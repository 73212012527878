import {
  createStore,
  applyMiddleware,
  combineReducers,
  AnyAction,
  CombinedState,
} from 'redux'
import thunk from 'redux-thunk'
import {
  persistReducer,
  persistStore,
  PersistConfig,
  Transform,
} from 'redux-persist'
import { Persistor } from 'redux-persist/es/types'
import storage from 'redux-persist/lib/storage'
import autoMergeLevel1 from 'redux-persist/lib/stateReconciler/autoMergeLevel1'
import createEncryptor from 'redux-persist-transform-encrypt'

import { start as startSession } from 'lib/session'

import isServer from 'utils/isServer'
import isDebug from 'utils/isDebug'

import { ActionTypes as AppActionTypes } from './app/types'

import { RootState } from './types'

import * as reducers from '.'

const appReducer = combineReducers(reducers)
const rootReducer = (state: RootState | undefined, action: AnyAction): CombinedState<RootState> => {
  let newState
  if (action.type === AppActionTypes.RESET_APP) {
    newState = undefined
  }
  else {
    newState = state
  }

  return appReducer(newState, action)
}

const middlewares = [ thunk ]
if (process.env.NODE_ENV !== 'production' && !isServer) {
  const { createLogger } = require('redux-logger') // eslint-disable-line @typescript-eslint/no-var-requires

  const logger = createLogger({ predicate: (getState: Function) => (getState().app && getState().app.isReduxLoggerEnabled) || false })

  middlewares.push(logger)
}

const appliedMiddlewares = applyMiddleware(...middlewares)

let sessionId = ''
if (!isServer) {
  sessionId = startSession()
}

const encryptor: Transform<RootState, RootState> = createEncryptor({
  secretKey: sessionId,
  onError: () => {
    if (!isServer) {
      if (isDebug) {
        console.warn('[DEBUG] Storage token expired, sessionId was', sessionId) // eslint-disable-line no-console
      }

      persistor.purge()
    }
  },
})

const persistConfig: PersistConfig<RootState, RootState> = {
  key: 'quotes',
  stateReconciler: autoMergeLevel1,
  storage,
  transforms: [ encryptor ],
  whitelist: [
    'analytics',
    'app',
    'quotes',
  ],
}
const persistedReducer = persistReducer(persistConfig, rootReducer)

export const store = createStore(persistedReducer, appliedMiddlewares)

// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
// @ts-ignore // Suppress "Type 'Persistor' is missing the following properties from type 'Persistor': persist, flush, dispatch, getState, subscribets(2739)" TSC error.
export const persistor: Persistor = persistStore(store)
