export const apiRootUrl = process.env.RAZZLE_API_ROOT_URL

export const apiBaseUrl =
  process.env.RAZZLE_API_BASE_URL || `${apiRootUrl}/api/v1`

export const echoHost = process.env.RAZZLE_ECHO_HOST

export const googleTagManager = {
  containerId: process.env.RAZZLE_GOOGLE_TAG_MANAGER_CONTAINER_ID,
  isEnabled: process.env.RAZZLE_GOOGLE_TAG_MANAGER_ENABLED === 'true',
}
